//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================
// $primary: #1F2D30;  // Changing primary color
// $primary: #FCC236; 
$primary: #213D77;
$chat-sidebar-width: 250px;
// $chat-bg-light: none
$floating-nav-margin: 0.5rem;

@media (max-width: 768px) {
  .card {
    margin-top: 2rem;
  }
}
